*{
  margin: 0px;
  padding: 0px;
}  
.row{
  width: 100%;
}

:root {
  --primary-color: #3b5998;
  --secondary-color: #6e7b95;
  --background-color: #eff1f6;



}

