.bsoftButton1{
    width: fit-content;
    height: fit-content;
    padding-top:8px ;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border: 2px solid var(--primary-color);
    font-size: 20px;
    border-radius: 7px;
    color: white;
}

.bsoftButton1:hover{
    opacity: 60%;
    cursor: pointer;
}

.bsoftButton1:active{
    transform: scale(.96);
}