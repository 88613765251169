.sectionAboutUsContainer{
    padding: 10px;
    
}

.imageCoverAboutUs{
    border-radius: 20px 400px 80px 60px;
}

.sectionAboutUsTextContainer{
    display: flex;
    justify-content: center;
    align-items: center;

}

.sectionAboutUsTextContainer p{
    font-size: 40px;
}