.navbarsmallontainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color: white;
    margin-bottom: 3px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.menuTogleButtonAndLogoContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-family: klavika-medium;
}
.menuButtonToogle{
    font-size: 35px;
    cursor: pointer;
}
.logoCharacter{
    margin-left: 7px;
    font-size: 25px;
}
.navbarSmallTopPart{
    padding: 10px;
}
.navbarSmallSeparatorLine{
    display: none;
    color: black;
}

.navbarSmallMenuContainer{
    display: none;
    width: 100%;
    
}

.navSmallMenuItem{
    margin-left: -25px;
    width: 100%;
    color: black;
    display: flex;
    margin-top: 10px;
    font-size: 23px;
    
}

.navbarSmallLeftContainer{
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarSmallRightContainer{
    float: right;
    display: flex;
}

.navbarSmallButtonSignin{
    padding: 10px;
    border-radius: 7px;

    margin-right: 10px;
    margin-left: 10px;
    font-size: 20px;
}

.navbarSmallButtonLogin{
    padding: 10px;
    border-radius: 7px;
   
    margin-right: 10px;
    margin-left: 10px;
    font-size: 20px;
}
