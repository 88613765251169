.emailVericationPageContainer{
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.logoContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 37px;
    color: black;
    margin-bottom: 40px;
}

.emailVerificationContainer{

}

.emailVerificationContainer .emailVerificationLabel{
    font-size: 40px;
    font-weight: bold;
}

.emailVerificationContainer .buttonSubmitVerification{
    margin-top: 40px;
    width: 100%;
}

.emailVerificationContainer .emailSentMessage .emailAddressSpan{
    font-weight: bold;
}

