sectionServices{
    height: fit-content;
    padding: 10px;
    background-color: black;
    color: white;
    
}

.serviceCardContainer{
    width: 100%;
    display: flex;

}


.serviceCardContainer0{
    width: 100%;
    
}
  
  
.serviceCardContainer1{
    padding: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    column-gap: 20px;
    row-gap: 20px; 
}


.serviceCard{
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
    width: 30vw;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.serviceCard .iconService{
    font-size: 80px;
}

.serviceCard:hover{
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
}




@media only screen and (max-width: 800px){

    .serviceCardContainer1{
        justify-content: center;
        align-items: center;
    }
    
    .serviceCardContainer1 .serviceCard{
        width: 100%;
        min-width: 320px;
    }    
    
}