.bsoftButton{
    width: fit-content;
    height: fit-content;
    padding-top:10px ;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: green;
    font-size: 20px;
    border-radius: 7px;
    color: white;
}

.bsoftButton:hover{
    opacity: 60%;
    cursor: pointer;
}

.bsoftButton:active{
    transform: scale(.96);
}