.inputTextConatiner{
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 17px;
}

.inputLabel{
  color: white;
}


.inputLabel{
  margin-bottom: 6px;
}


.bsoftTextInput {
  padding: 10px;
  border-radius: 7px;
  line-height: 1.5;
  background-clip: border-box;
  border: 1px solid #ced4da ;
  transition: border-color .15s ease-in-out;
  appearance: none;

  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.bsoftTextInput:focus{
  box-shadow: 0 0 0 0.25rem var(--primary-color);
  outline: 0;
}


.bstyleInputAlert{
  color: red;
}