.heroContainer{
    width: 100%;
    background-color: blanchedalmond;
    background-image: url("/public/img/hero0.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}  

.titleAndButtonHeroContainer{
    padding-top: 100px;
    padding-bottom: 300px;
    padding-left: 10%;

}

.heroTitle{
    font-weight: bold;
    font-size: 120px;
    color: white;
}

.heroSubtitle{
    font-size: 60px;
    color: white;
    font-weight: bold;
}

.buttonHero{
    margin-top: 35px;
}



@media only screen and (max-width: 800px){
    .heroTitle{
        font-size: 60px;
        color: white;
    }

    .heroSubtitle{
        font-size: 45px;
        color: white;
        font-weight: bold;
    }
    
    
}