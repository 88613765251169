.loginPageContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
}

.loginPageLogoContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 37px;
    color: black;
    margin-bottom: 40px;
}

.loginPageContainer .loginCardContainer .formTitle{
    width: 100%;
    color: black;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginCardContainer{
    width: 400px;
    border-radius: 7px;
    padding: 15px;
    margin-bottom: 70px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    background-color: white;
}

.bstyleGreenLink{
    color: green; /* Set the color to green */
    text-decoration: none; /* Remove the underline */
}

.bstyleGreenLink:hover{
    color: green; /* Set the color to green */
    text-decoration: none; /* Remove the underline */
}

@media only screen and (max-width: 600px) {
    .loginCardContainer{
        width: 80%;
        min-width: 320px;
    }
}


.buttonSubmitLoginInfo{
    margin-top: 20px;
    margin-bottom: 50px;
}