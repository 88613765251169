*{
    text-decoration:none; 
    list-style: none;

}

a{
    text-decoration:none; 
    color: black;
    cursor: default;
}

a:hover {
    text-decoration:none; 
    color: black;
}

.list-item .link{
    cursor: pointer;
}


.footer{
    background-color: white;

}

.footerContainer{
    width: 100%;
    height: fit-content;
    padding-top: 40px;
    padding-bottom: 20px;
    color:black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footerBrandContainer{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.logo_container{
    display: flex;
    flex-direction: row;
}

.brandCopyRight{
    padding-top: 10px;
    padding-bottom: 10px;
}


.Socialicon {
    margin-left: 10px;
}

.footerContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    
.footerSubContainer0{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.footerSubContainer2{
    display: flex;
    flex-wrap: wrap ;
    flex-direction: row;
    column-gap: 200px;
    row-gap: 20px;
}

.footerColum{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footerColum0{
    margin-top: -12px;
}

.footerColumTitle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
