.textareaConatiner{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    
}


.bstyleTextarea {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    border: 1px solid #ced4da ;
    transition: border-color .15s ease-in-out;
    appearance: none;
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  
  
.bstyleTextarea:focus{
    box-shadow: 0 0 0 0.25rem var(--primary-color);
    outline: 0;
}

.textareaLabel{
    margin-bottom: 15px;
}

.bstyleTextarea{
    
}
  
  