.bstyleCheckboxConatiner{
    margin-top: 20px;
    margin-bottom: 20px;
}


.bstyleCheckboxAlertMessage{
    color: red;
}


.bstyleInputCheckbox {
    float: left;
    margin-right: 10px;
    color: #4accd5;
    -webkit-appearance: none;
    appearance: none;
    font: inherit;
    width: 24px;
    height: 24px;
    border: 0.15em solid #4accd5;
    border-radius: 5px;
    transform: translateY(-0.075em);  
    display: grid;
    place-content: center;
    background-color: white;
    
}


.bstyleInputCheckbox::before {
    content: "";
    width: 18px;
    height: 18px;
    background-color: white;
    transform: scale(1);
    transition: 120ms transform ease-in-out;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.bstyleInputCheckbox:checked{
    background-color: white;
}


.bstyleInputCheckbox:checked::before {
   background-color: #4accd5;
}


.bstyleCheckboxLabel {
    color: black;
}    

