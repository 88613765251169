.SectionHeadContainer{
    width: 100%;
    height: fit-content;
    padding: 15px;
    font-style: italic;
}

.SectionHeadSolid{
    width: 100px;
    height: 20px;
    background-color: var(--primary-color);
    border-radius: 50px;
    
}

.sectiontitle{
    font-family: 'Times New Roman', Times, serif;
    font-size: 40px;
    color: black;
    font-weight: bold;
}
