.TopHorizontalMenu{
   width: 100%;


}


.navbarContainer{
    width: 100%;
    height: 80px;
    background-color: white;
    margin-bottom: 3px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.navbarBrandContainer{
    font-size: 25px;
    font-family: klavika-medium;
    color: white;
}


.menuItemsUnorderList{
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;

}

.navMenuItem{
    padding-left: 15px;
    color: black;
}

.navbarLeftContainer{
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarRightContainer{
    float: right;
    display: flex;
}

.navbarButtonSignin{
    padding: 10px;
    border-radius: 7px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 20px;
}

.navbarButtonLogin{
    padding: 10px;
    border-radius: 7px;
   
    margin-right: 10px;
    margin-left: 10px;
    font-size: 20px;
}
