.bsoftButton{
    width: 100%;
    height: fit-content;
    padding-top:10px ;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    font-size: 20px;
    border-radius: 7px;
    color: #4accd5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bsoftButton:hover{
    opacity: 60%;
    cursor: pointer;
}

.bsoftButton:active{
    transform: scale(.96);
}