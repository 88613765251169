.signUpPageContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.logoContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 37px;
    color: black;
    margin-bottom: 40px;
}

.formTitle{
    width: 100%;
    color: black;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signUpCardContainer{
    width: 430px;
    background-color:white;
    border-radius: 7px;
    padding: 15px;
    margin-bottom: 70px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);

}

.checkboxInputContainer{
    font-size: 16px;
    color: white;
}

.bstyleLink{
    color: var(--primary-color); /* Set the color to #4accd5 */
    text-decoration: none; /* Remove the underline */
}

.bstyleLink:hover{
    color: var(--primary-color); 
    text-decoration: none; /* Remove the underline */
}

@media only screen and (max-width: 600px) {
    .signUpCardContainer{
        width: 80%;
        min-width: 320px;
    }
}

.inputText{
    margin-top: 10px;
    margin-bottom: 10px;
}

.buttonSubmitAccount{
    margin-top: 30px;
    margin-bottom: 15px;
}