.sectionContactUs{
  padding: 10px;
  display: flex;
  justify-content: center;
}

.sectionContactUsContainer{
  width: 100%;
  margin-bottom: 45px;
  display: flex;
  justify-content: center;
}
.imageCoverContactUs{
  border-radius: 60px 60px 400px 200px;
}

.buutonSendEmail{

}

.ContactUsImg{
    border-radius: 7px;
}